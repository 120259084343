import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppDirectoryPage from './pages/AppDirectoryPage';

function App() {
  return (
    <Authenticator hideSignUp>
      {({ signOut, user }) => (
        <Router>
          <Routes>
            <Route path='/' element={<AppDirectoryPage user={user} />} />
          </Routes>
          <button onClick={signOut}>Sign Out</button>
        </Router>
      )}
    </Authenticator>
  );
}

export default App;
