import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Modal,
} from '@mui/material';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

// Example applications data
const applications = [
  {
    name: 'Analytics',
    subdomain: 'ndanalytics.bqintranet.com',
    permissions: 'NDAnalytics_View',
  },
  {
    name: 'Admin Panel',
    subdomain: 'admin.bqintranet.com',
    permissions: 'Admin',
  },
];

// const userPermissions = ['Admin', 'NDAnalytics_View', 'NDAnalytics_Edit']; // Example user permissions

function AppDirectoryPage() {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);
  const [accessToken, setAccessToken] = useState('');

  const filteredApplications = applications.filter((app) =>
    userPermissions.includes(app.permissions)
  );

  const handleAppClick = (subdomain) => {
    window.location.href = `https://${subdomain}?token=${accessToken}`;
  };

  useEffect(() => {
    const fetchUserSession = async () => {
      const session = await fetchAuthSession();
      setUserPermissions(session.tokens.accessToken.payload['cognito:groups']);
      setAccessToken(session.tokens.accessToken.toString());
    };
    fetchUserSession();
  }, []);

  return (
    <div>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id='modal-title'
            variant='h5'
            gutterBottom
            textAlign='center'
          >
            Application Directory
          </Typography>
          <List>
            {filteredApplications.map((app) => (
              <ListItemButton
                key={app.name}
                onClick={() => handleAppClick(app.subdomain)}
              >
                <ListItemText primary={app.name} />
              </ListItemButton>
            ))}
          </List>
          <Box textAlign='center' mt={2}></Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AppDirectoryPage;
